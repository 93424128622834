@import url(https://fonts.googleapis.com/css2?family=Martel:wght@400;600;800&family=Work+Sans:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  overflow: hidden;
}

.app-container {
  flex-wrap: wrap;
}

.app-container > h1 {
  padding-top: 50px;
}

body {
  background-color: #f5f5f5;
  font-family: 'Work Sans', sans-serif;
  color: black;
}

.minheight {
  min-height: 90vh;
}

.fullscreen {
  color: white;
  background-color: #212121;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  text-align: right;
}

.fullscreen > .app-container {
  display: flex;
  margin-top: 25px;
  justify-content: flex-end;
}

.fullscreen ul {
  list-style-type: none;
}

.fullscreen li {
  padding-bottom: 10px;
  letter-spacing: -0.02em;
}

.fullscreen a {
  color: white;
  font-family: 'Martel', serif;
  font-size: 2.3em;
  font-weight: bold;
  text-decoration: none;
}

.fullscreen a:hover {
  color: gray;
  transition: 0.5s;
}

.fullscreen.open {
  display: flex;
}

.fullscreen.closed {
  display: none;
}

.backdrop {
  z-index: -1;
  object-fit: cover;
  height: 20vh;
  width: 100vw;
  overflow: hidden;
}

.footer-backdrop {
  height: 20px !important;
}

.backdrop img {
  width: 100%;
}

h1 {
  font-size: 4.5em;
  line-height: 0.87em;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

h2 {
  font-size: 1.35em;
  font-family: 'Martel', serif;
  font-weight: 400;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-root {
  position: relative;
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center
}

.navbar {
  min-height: 145px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; 
  font-weight: 500;
  font-family: 'Martel', serif;
  border-bottom: 1px solid white;
  background: white;
  color: black;
}

.navbar.transparent {
  color: white;
  background: transparent;
}

.navbar.transparent a {
  color: white;
}

.navbar.open, .navbar.open a {
  color: white;
}

.navbar.open {
  background-color: #212121;
}

.rectangles > div {
  width: 100vw;
}

.rectangles > div:first-child {
  height: 40px;
  background-color: #313233;
}

.rectangles > div:nth-child(2) {
  height: 25px;
  background-color: #5c5c5c;
}

.rectangles > div:nth-child(3) {
  height: 20px;
  background-color: #47a0cc;
}

.footer {
  border-top: 1px solid #e6e6e6;
  background-color: white;
  min-height: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer .inner {
  padding-top: 10px;
}

.footer a {
  color: black;
  margin-right: 20px;
}

.footer .logo {
  font-weight: 500;
  font-family: 'Martel', serif;
}

.logo {
  margin-top: 18px;
  font-size: 0.5em;
}

.footer .tag {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
}

.tag p {
  margin-right: 20px;
}

.navbar .inner {
  display: flex;
  align-items: center;
  justify-content: space-between; 
}

.navbar a {
  text-decoration: none;
  color: black;
}

.navbar a:hover, .footer a:hover {
  color: gray;
  transition: 0.5s;
}

.navbar li {
  display: inline;
  padding: 20px;
}

.routes {
  position: relative;
}

.painting {
  color: black;
  background-color: white;
  padding: 12.5px;
  width: 300px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  position: relative;
}

.painting > .metadata {
  opacity: 0;
  transition: 0.3s ease-in-out;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #114c82;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.painting > .metadata > .text {
  padding: 20px;
  display: flex;
  color: white;
  flex-direction: column;
}

.painting p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
          line-clamp: 4; 
  -webkit-box-orient: vertical;
}

.painting .btn {
  margin-top: 20px;
  border: 0px;
  color: white;
  border: 1px solid white;
}

.painting .metadata h2 {
  margin: 0;
  font-size: 1.65em;
  padding: 0;
}

.painting:hover > .metadata {
  opacity: 1;
}

.dashboard > .app-container {
  padding: 25px;
  border-bottom: 1px solid #cacaca;
}

.dashboard ul {
  list-style: none;
  font-size: 1.5em;
  padding: 0;
}

.dashboard ul li {
  padding-bottom: 15px;
}

.dashboard ul a {
  text-decoration: none;
  color: black;
}

.dashboard h3 {
  font-weight: 400;
}

.detailed-painting > .metadata > h1 {
  padding-top: 25px;
}

.detailed-painting > .metadata > p {
  padding-top: 25px;
  line-height: 1.4em;
}

.painting .painting-image {
  width: 100%;
  height: 100%;
}

.loading {
  align-self: center;
  justify-self: center;
  height: 75px;
  width: 75px;
}

.hero {
  color: white;
  margin-top: 15%;
  margin-bottom: 15%;
}

.hero h1 {
  padding: 0;
  margin: 0;
  font-size: 10em;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 0.9em;
}

.hero h2 {
  font-family: 'Martel', serif;
  font-weight: 500;
  font-size: 1.65em;
}

.about {
  background-color: white;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: 70vh;
}

.about > div {
  flex-direction: column;
  display: flex;
}

.about h2 {
  font-family: 'Martel';
  font-weight: 300;
  font-size: 1em;
}

.about img {
  height: 500px;
}

.about .info {
  padding: 70px;
  line-height: 1.65em;
  width: 500px;
}

.about-page > div > div {
  margin-top: 50px;
}

.about-page .image {
  height: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  flex-wrap: nowrap;
}

.about-page img { 
  height: 100%;
  margin-right: 5px
}

.about-page p {
  line-height: 1.65em;
  max-width: 800px;
  margin-bottom: 100px;
}

.about-page img {
  object-fit: cover;
}

.artwork {
  min-height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overlay {
  background-color: black;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -5;
  opacity: 0.05;
}

.background-image {
  z-index: -10;
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.interested {
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: 1px solid #e6e6e6;
  background-color: white;
  min-height: 350px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.interested > div {
  align-items: flex-start;
}

.interested h1 {
  font-size: 3em;
  padding: 0;
}

.interested h3 {
  font-weight: normal;
  font-family: 'Martel', 'serif';
}

.interested .btn {
  margin-right: 10px;
}

.editable {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 5px;
  padding: 12.5px;
  min-width: 300px;
  min-height: 350px;
}

.editable-inner {
  max-width: 90vw;
  display: flex;
  flex-direction: column;
}

.editable-inner h2 {
  font-size: 2em;
  font-weight: bold;
  font-family: 'Work Sans', sans-serif;
}

.editable input {
  padding: 10px;
  margin-bottom: 5px;
}

.editable .painting-image {
  width: 300px;
  height: 350px;
}

.painting-image {
  object-fit: cover;
}

.editable .painting {
  width: auto;
  height: auto;
}

.paintings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.left {
  justify-content: left;
}

.artwork > .paintings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.artwork > div {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.artwork h2 {
  font-family: 'Martel', serif;
  font-weight: 400;
}

.artwork .buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.artwork > .buttons > a {
  padding: 5px;
}

.dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detailed-view {
  min-height: 80vh;
}

.detailed-painting {
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 25px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
}

.dashboard h1 {
  margin: 0;
  padding-top: 20px;
}

.loading {
  align-self: center;
  justify-self: center;
}

.detailed-painting .painting-image {
  min-width: 300px;
  min-height: 350px;
  max-width: 100%;
  max-height: 75%;
}

.btn {
  background-color: transparent;
  border: 1px solid black;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition: 0.2s ease-in-out;
  font-family: "Martel", serif;
  margin-top: 10px;
}

.normal:hover {
  background-color: #114c82;
  color: white;
  border: 1px solid #3597f2;
}

.white {
  color: white;
  border-color: white;
}

.white:hover {
  background-color: white;
  color: #3597f2;
  border: 1px solid #3597f2;
}

.btn:hover {
  cursor: pointer;
}

.success {
  background-color: #2ab561;
  color: white;
  border-color: transparent;
}

.success:hover {
  background-color: #2fd671;
  color: white;
}

.danger {
  background-color: #fc4842;
  color: white;
  border-color: white;
}

.danger:hover {
  background-color: #fc6f6a;
  color: white;
}

.page {
  font-family: 'Work Sans', sans-serif;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: left;
  flex-direction: column;
}

.works {
  min-height: 90vh;
}

.works h1 {
  padding-top: 50px;
}

.works h2 {
  font-family: 'Martel', serif;
  font-weight: 400;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 50px;
}

input[type="text"], input[type="password"] {
  padding: 15px;
  border: 1px solid #cacaca;
  font-size: 1.05em;
  border-radius: 5px;
}

textarea {
  padding: 15px;
  border: 1px solid #cacaca;
  font-size: 1.05em;
  border-radius: 5px;
}

.error {
  color: red;
}

.login-page {
  min-height: 90vh;
}

.login-page > div {
  max-width: 500px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.login-page > div > h1 {
  padding-bottom: 25px;
}

@media only screen and (max-width: 600px) {
  .app-container {
    width: 90vw;
  }

  .hero h1 {
    font-size: 3.5em;
  }

  .hero h2 {
    font-size: 1.25em;
  }

  .about .info {
    padding: 30px;
  }

  .backdrop {
    height: 10vh;
  }

  .logo {
    font-size: 0.75em;
  }

  h1 {
    font-size: 2.6em;
  }

  .painting {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .app-container {
    width: 90vw;
  }

  .hero h1 {
    font-size: 6em;
  }

  .backdrop {
    height: 10vh;
  }

  h1 {
    font-size: 2.8em;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .app-container {
    width: 85vw;
  }

  .backdrop {
    height: 15vh;
  }

  .hero h1 {
    font-size: 8em;
  }

  h1 {
    font-size: 4.5em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .app-container {
    width: 75vw;
  }

  .hero h1 {
    font-size: 10em;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .app-container {
    width: 55vw;
  }
}
